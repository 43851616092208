import { Trans } from '@lingui/macro';
import { Box, BoxProps, Typography } from '@mui/material';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { getTournamentCount } from 'src/api/tournament';
import ScrollableTabs from 'src/components/core/scrollable-tabs';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';
import { getTournamentCountKey } from 'src/utils/helpers/swr';
import useSWR from 'swr';

const Casino = dynamic(() =>
  import('src/components/modules/leaderboard/casino').then(
    (r) => r.LatestBetCasino,
  ),
);
const Sport = dynamic(() =>
  import('src/components/modules/leaderboard/sport').then((r) => r.LatestBetV2),
);
const Tournament = dynamic(() =>
  import('src/components/modules/leaderboard/tournament').then(
    (r) => r.Tournament,
  ),
);

export enum LeaderboardTab {
  Casino = 'casino',
  Sports = 'sports',
  Tournaments = 'tournaments',
}

interface MyTab {
  value: LeaderboardTab;
  title: React.ReactNode;
}

export const Leaderboard: React.FC<BoxProps & { tabs?: MyTab[] }> = ({
  tabs = [],
  ...boxProps
}) => {
  const { data: counts } = useSWR(getTournamentCountKey(), getTournamentCount);
  const hasLive =
    (_.find(counts, { status: TOUR_TIME_STATUS.LIVE })?.count ?? 0) > 0;

  const allTabs = hasLive
    ? tabs
    : tabs?.filter((x) => x.value !== LeaderboardTab.Tournaments);

  const [currentTab, setCurrentTab] = useState<LeaderboardTab | false>(
    _.first(allTabs)?.value || false,
  );
  useEffect(() => {
    setCurrentTab((prev) => {
      return (
        allTabs.find((x) => x.value === prev)?.value ||
        _.first(allTabs)?.value ||
        false
      );
    });
  }, [allTabs.length]);

  return (
    <Box {...boxProps}>
      <Typography gutterBottom variant="18" fontWeight={700}>
        <Trans id="home.leaderboard.title">Leaderboards</Trans>
      </Typography>
      {allTabs.length > 1 && (
        <ScrollableTabs
          variant="fullWidth"
          containerProps={{
            sx: { mb: '10px !important' },
          }}
          tabProps={{ sx: { minWidth: '100px' } }}
          onChange={(_, next) => {
            setCurrentTab(next);
          }}
          tabs={allTabs}
          value={currentTab}
        />
      )}

      {currentTab === LeaderboardTab.Tournaments ? (
        hasLive && <Tournament />
      ) : currentTab === LeaderboardTab.Casino ? (
        <Casino />
      ) : currentTab === LeaderboardTab.Sports ? (
        <Sport />
      ) : null}
    </Box>
  );
};
