import { Box, BoxProps, Skeleton, Typography } from '@mui/material';
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { KeenSliderPlugins, MyDevices } from 'src/utils/helpers/etc';
import { IPagination } from 'src/utils/types/response';
import { useStyles } from './styles';

interface CasinoCarouselProps<T> extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode;
  data?: IPagination<T>[];
  loading?: boolean;
  href?: string;
  stackItems?: boolean;
  maxNumberOfItems?: number;
  renderItem: (data: T) => React.ReactNode;
}

const MAX_NUMBER_STACKED_ITEMS = 15;
function CasinoCarousel<T>({
  title,
  data,
  loading,
  href,
  stackItems = false,
  maxNumberOfItems,
  renderItem,
  ...rest
}: CasinoCarouselProps<T>) {
  const { classes, cx } = useStyles();

  const isMobile = useBreakpoint(MyDevices.MOBILE);

  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 3.2,
        spacing: 12,
      },
      breakpoints: {
        '(min-width: 500px)': {
          slides: {
            perView: 5,
            spacing: 12,
          },
        },
      },
    },
    KeenSliderPlugins,
  );

  const maxItems = maxNumberOfItems ?? MAX_NUMBER_STACKED_ITEMS;

  const filteredData = data
    ?.flatMap((d) => d.items)
    .filter((d) => d)
    .filter((d, index) => index <= maxItems - 1);

  if (!loading && !filteredData?.length) return null;

  const renderItems = () => {
    if (loading) return null;
    if (stackItems && !isMobile) {
      return (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridGap: '16px',
          }}>
          {filteredData?.map((d, index) => (
            <Box key={index}>{renderItem(d)}</Box>
          ))}
        </Box>
      );
    }

    return (
      !loading &&
      filteredData?.length && (
        <Box ref={sliderRef} className="keen-slider" sx={{}}>
          {data
            ?.flatMap((d) => d.items)
            .map((d, index) => (
              <Box className="keen-slider__slide" key={index}>
                {renderItem(d)}
              </Box>
            ))}
        </Box>
      )
    );
  };
  return (
    <Box sx={{ mb: 3 }} {...rest}>
      {title && (
        <Box className={classes.titleContainer} sx={{ mb: 2 }}>
          <Box className={classes.titleWrapper}>
            {href ? (
              <MyLink className={classes.link} href={href}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}>
                  {title}
                </Typography>
                <ArrowRight
                  height={16}
                  style={{ marginLeft: '8px', marginBottom: '-4px' }}
                />
              </MyLink>
            ) : (
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                }}>
                {title}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {loading && (
        <div className={classes.skeletonContainer}>
          {[...Array(3)].map((_, item) => {
            return <Skeleton key={item} width={'100%'} height={120} />;
          })}
        </div>
      )}
      {renderItems()}
    </Box>
  );
}

export default CasinoCarousel;
