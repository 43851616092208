import { makeStyles } from 'tss-react/mui';

const THUMBNAIL_WIDTH = 130;

export const useStyles = makeStyles()((theme) => {
  return {
    titleContainer: {
      display: 'flex',
      marginBottom: '20px',
    },
    titleWrapper: { display: 'flex', alignItems: 'center', flex: 1 },
    wrapper: {
      minWidth: '100%',
      display: 'flex',
      width: '0px !important',
      height: `${THUMBNAIL_WIDTH}px`,
    },
    slideItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '3px',
      minWidth: `${THUMBNAIL_WIDTH}px`,
      minHeight: `${THUMBNAIL_WIDTH}px`,
    },
    allText: {
      fontSize: '14px',
      color: theme.palette.primary.main,
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
    },
    skeleton: {
      flex: 1,
      marginRight: '20px',
    },
    skeletonContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '20px',
      height: '120px',
      marginRight: '0',
      '&> span': {
        transform: 'scale(1, 1)',
      },
    },
  };
});
