import moment from 'moment';
import {
  TOUR_TIME_STATUS,
  getTourTimeStatusOptions,
} from '../constants/tournament';
import { TournamentItem } from '../types/tournament';

export const calcTourStatus = (data?: TournamentItem) => {
  const now = moment();

  // if (calcReward && now.isAfter(data?.rewardTime)) {
  //   return TOUR_TIME_STATUS.REWARDED;
  // }
  if (now.isAfter(data?.endTime)) {
    return TOUR_TIME_STATUS.ENDED;
  }
  if (now.isAfter(data?.startTime)) {
    return TOUR_TIME_STATUS.LIVE;
  }

  return TOUR_TIME_STATUS.UPCOMING;
};

export const getCountTime = (
  data?: TournamentItem,
  status?: TOUR_TIME_STATUS,
) => {
  if (status === TOUR_TIME_STATUS.UPCOMING) {
    return data?.startTime;
  }
  if (status === TOUR_TIME_STATUS.LIVE) {
    return data?.endTime;
  }
};

export const getTimeStatusToLabel = (status: string | TOUR_TIME_STATUS) => {
  const label =
    getTourTimeStatusOptions().find((o) => o.value === status)?.label ?? '';

  return label;
};
