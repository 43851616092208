import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHarmonicIntervalFn, useInterval } from 'react-use';
export const useTimer = (initialSeconds: number = 300) => {
  const [value, setValue] = useState(0);

  useInterval(
    () => {
      if (value > 0) {
        setValue(value - 1);
      }
    },
    value > 0 ? 1000 : null,
  );

  function execute() {
    setValue(initialSeconds);
  }

  function reset(stop = false) {
    setValue(stop ? 0 : initialSeconds);
  }

  return [value, execute, reset] as [
    number,
    () => void,
    (stop?: boolean) => void,
  ];
};

export const useSyncTimer = (target?: string | number | null, ms = 1000) => {
  const [obj, setObj] = useState(
    target
      ? moment.duration(moment(target).diff(moment(), 'milliseconds'))
      : moment.duration(),
  );

  useHarmonicIntervalFn(
    () => {
      const now = moment();
      const end = moment(target);

      setObj(moment.duration(end.diff(now), 'milliseconds'));
    },
    target && obj.asSeconds() ? ms : null,
  );

  useEffect(() => {
    if (target) {
      setObj(moment.duration(moment(target).diff(moment(), 'milliseconds')));
    }
  }, [target]);

  return obj;
};

const useCountdown = (
  exprideDate: number = new Date().getTime() + 5 * 60 * 1000,
) => {
  const [countDownDate, setCountDownDate] = useState(0);

  const [countDown, setCountDown] = useState(0);

  const seconds = Math.floor(countDown / 1000);

  const enable = seconds > 0;

  useEffect(() => {
    let interval = setInterval(() => {});

    if (enable) {
      interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);
    } else clearInterval(interval);

    return () => clearInterval(interval);
  }, [countDownDate, enable]);

  function execute() {
    setCountDownDate(new Date(exprideDate).getTime());
    setCountDown(new Date(exprideDate).getTime() - new Date().getTime());
  }

  return [seconds, execute] as [number, () => void];
};

export { useCountdown };
