import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { fill } from 'lodash';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import FixtureLoading from 'src/components/core/loading/fixture-loading';
import { MyLink } from 'src/components/core/mine/my-link';
import { useSWREvents } from 'src/hooks/use-events';
import { SBLayoutProvider } from 'src/hooks/use-sb-layout';
import { Paths } from 'src/utils/constants/path';
import { getTypeSport, toTournaments } from 'src/utils/helpers/sport';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { FixtureStatus } from 'src/utils/types/event';
import { SportTypeEnum } from 'src/utils/types/sport';
import { FixtureTableV2 } from '../sportsbook-v2/fixture';
import { SeasonItemV2 } from '../sportsbook-v2/season';
import { useStyles } from './styles';

const MAX_NUMBER_OF_ITEMS = 5;
type LiveSportsProps = {};
const LiveSports: React.FC<LiveSportsProps> = () => {
  const { classes, cx } = useStyles();
  const { data, loading } = useSWREvents({
    status: FixtureStatus.LIVE,
    sportId_like: getTypeSport(SportTypeEnum.Sport),
    slug: undefined,
    page_size: 5,
  });

  const grouped = toTournaments(data);

  if (!loading && !grouped.length) return null;
  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          alignItems: 'center',
          mb: 2,
        }}>
        <MyLink
          href={generatePath(Paths.MetaLiveSport, {
            sportType: SportTypeEnum.Sport,
          })}
          color="inherit"
          style={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}>
            <Trans id="sportsbook.live_now">Live sports</Trans>
          </Typography>
          <div style={{ marginLeft: 8, marginBottom: '-4px' }}>
            <ArrowRight />
          </div>
        </MyLink>
      </Box>
      <div>
        {loading && <FixtureLoading />}
        {!loading &&
          grouped
            .filter((_, index) => index < MAX_NUMBER_OF_ITEMS)
            .map((season, index) => (
              <SBLayoutProvider
                defaultValues={{
                  nation: true,
                  seasons: fill(Array(grouped.length), true),
                }}
                key={index}>
                <SeasonItemV2
                  index={index}
                  className={classes.season}
                  data={season}>
                  <FixtureTableV2 data={season.__events} index={index} />
                </SeasonItemV2>
              </SBLayoutProvider>
            ))}
      </div>
    </Box>
  );
};

export default LiveSports;
