import { BoxProps, Typography } from '@mui/material';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';
import { getTimeStatusToLabel } from 'src/utils/helpers/tournament';

const statusToStyles = {
  [TOUR_TIME_STATUS.UPCOMING]: {
    bgcolor: '#1F2126', //'custom.content.3',
    color: '#97B2D2',
  },
  [TOUR_TIME_STATUS.LIVE]: {
    bgcolor: 'custom.status.green',
  },
  [TOUR_TIME_STATUS.ENDED]: {
    bgcolor: 'custom.bg.3',
    color: 'custom.content.3',
  },
  // [TOUR_TIME_STATUS.REWARDED]: {
  //   bgcolor: 'custom.bg.3',
  //   color: 'custom.content.3',
  // },
} as Record<TOUR_TIME_STATUS, { bgcolor: string; color: string }>;

export const TournamentBadge: React.FCC<
  {
    status?: TOUR_TIME_STATUS;
  } & BoxProps
> = (props) => {
  const { status = TOUR_TIME_STATUS.UPCOMING, ...boxProps } = props;

  return (
    <Typography
      variant="caption"
      component="span"
      {...boxProps}
      sx={{
        borderRadius: 1,
        m: 1,
        top: 0,
        padding: '3px 5px',
        fontWeight: 700,
        letterSpacing: '0.4px',
        textTransform: 'uppercase',
        ...statusToStyles[status],
        ...boxProps?.sx,
      }}>
      {getTimeStatusToLabel(status)}
    </Typography>
  );
};
