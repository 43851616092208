import { Trans } from '@lingui/macro';
import { Box } from '@mui/material';
import { useSwrCasinoGames } from 'src/hooks/use-casino-games';
import { Paths } from 'src/utils/constants/path';
import CasinoCarousel from '../casino-carousel';
import { CasinoThumbnail } from '../casino/casino-thumbnail';

const TopCasinoGames: React.FC = () => {
  const { data, isLoading } = useSwrCasinoGames({
    page_index: 0,
    page_size: 10,
    betuTopPick: 1,
  });

  const filteredData = data?.filter((d) => d);
  if (!isLoading && !filteredData?.length) return null;

  return (
    <Box sx={{ mb: 3 }}>
      <CasinoCarousel
        data={data}
        loading={isLoading}
        title={<Trans id="home.top.casino">Top casino games</Trans>}
        href={Paths.LobbyBetUTopPicks}
        stackItems
        renderItem={(d) => {
          return <CasinoThumbnail data={d} />;
        }}
        sx={{
          marginBottom: '0',
        }}
      />
    </Box>
  );
};

export default TopCasinoGames;
