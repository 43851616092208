import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { sportIdDirAtom, tournamentAtom } from 'src/store/sport';
import { SportTypeEnum } from 'src/utils/types/sport';
import { useNavList } from '../left-side-nav/components/use-nav-list';
import { useStyles } from './styles';

const MAX_NUMBER_OF_TOURNAMENTS = 10;
type FeaturedLeaguesProps = {
  type?: SportTypeEnum;
};
const FeaturedLeagues: React.FC<FeaturedLeaguesProps> = ({
  type = SportTypeEnum.Sport,
}) => {
  const { classes, cx } = useStyles();
  const tournaments = useAtomValue(tournamentAtom);
  const sports = useAtomValue(sportIdDirAtom);

  const { redirectTo } = useNavList(type);

  const items = _.take(
    type === SportTypeEnum.Sport ? tournaments.sport : tournaments.eSport,
    MAX_NUMBER_OF_TOURNAMENTS,
  );

  if (_.isEmpty(items)) return null;

  return (
    <Box
      sx={{
        mb: 3,
        display: 'none',
        [QueryMapping.mobile]: {
          display: 'block',
        },
      }}>
      <Box
        sx={{
          alignItems: 'center',
          mb: 2,
        }}>
        <Box
          color="inherit"
          style={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}>
            {type === SportTypeEnum.Sport ? (
              <Trans id="left_nav.featured_sport">Hot sports</Trans>
            ) : (
              <Trans id="left_nav.featured_esport">Hot esports</Trans>
            )}
          </Typography>
          <div style={{ marginLeft: 8, marginBottom: '-4px', display: 'none' }}>
            {/** For now do not display as link */}
            <ArrowRight />
          </div>
        </Box>
      </Box>
      {/* {isLoading && (
        <div>
          <FeatureLeaguesLoading />
        </div>
      )} */}
      <Box
        sx={{
          overflow: 'hidden',
        }}>
        <Box className={classes.carousel}>
          {items.map((t) => {
            const sport = _.get(sports, [t.sportId]);

            return (
              <MyLink
                color="inherit"
                key={t._id}
                href={redirectTo(sport?.slug, t.tournamentId)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& img': {
                    marginRight: '8px',
                  },
                }}>
                <img src={sport?.logo} alt="Sport logo" />
                <Typography variant="body2" color="custom.content.1">
                  {t.name}
                </Typography>
              </MyLink>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedLeagues;
