import { Trans } from '@lingui/macro';
import * as React from 'react';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { Paths } from 'src/utils/constants/path';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  backLink: {
    color: theme.custom.content[2],
  },
  icon: {
    fill: 'currentColor',
    marginRight: theme.spacing(1),
  },
}));
interface BackToWalletProps {
  to?: string;
  text?: React.ReactNode;
}

const BackToWallet: React.FCC<BackToWalletProps> = ({
  to = Paths.Wallet,
  text = <Trans id="wallet.back_to_wallet">Back to Wallet</Trans>,
}) => {
  const { classes, cx } = useStyles();

  return (
    <MyLink
      color="inherit"
      className={classes.backLink}
      href={{ pathname: to }}>
      <ArrowLeft className={classes.icon} />
      {text}
    </MyLink>
  );
};

export default BackToWallet;
