import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: 'flex',
      marginBottom: '20px',
    },
    carousel: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      left: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      overflowX: 'scroll',
      position: 'relative',
      right: '50%',
      scrollSnapType: 'x mandatory',
      scrollbarWidth: 'none',
      width: '100vw',
      paddingLeft: '8px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      '& > a': {
        padding: '8px',
        marginRight: '10px',
        whiteSpace: 'nowrap',
        flex: 1,
        display: 'inline-flex',
        width: 'auto',
        borderRadius: '4px',
        background: theme.custom.bg[3],
      },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      color: 'r',
      '& img': {
        marginRight: '8px',
      },
    },
  };
});
