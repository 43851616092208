import { useAtomValue } from 'jotai';
import React from 'react';
import { emailVerifiedAtom, loggedAtom } from 'src/store/auth';
import { depositedAtom } from 'src/store/wallet';
import { Stepper } from './components/stepper';

export const BannerV6: React.FCC = () => {
  const logged = useAtomValue(loggedAtom);
  const emailVerified = useAtomValue(emailVerifiedAtom);
  const deposited = useAtomValue(depositedAtom);

  if (!logged || (emailVerified && deposited)) {
    return null;
  }

  return <Stepper />;
};
