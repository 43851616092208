import _ from 'lodash';
import { useUnmount } from 'react-use';
import { getFixtureList } from 'src/api/sportbook-v2/event';
import { WithoutPagination, getFixtureListKey } from 'src/utils/helpers/swr';
import { BaseEvent } from 'src/utils/types/event';
import { IPagination } from 'src/utils/types/response';
import { GetFixtureListRequest } from 'src/utils/types/sportsbook';
import { useSWRConfig } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import { useLocale } from './use-locale';

const PAGE_SIZE = 10;

interface UseSWREventOptions
  extends SWRInfiniteConfiguration<IPagination<BaseEvent>> {
  disableCache?: boolean;
}

export const useSWREvents = (
  req?: WithoutPagination<GetFixtureListRequest> | null | false,
  options?: UseSWREventOptions,
) => {
  const { locale } = useLocale();

  const enable = req && (req?.slug || req.sportId_like);

  const { disableCache, ...opts } = options || {};

  const res = useSWRInfinite(
    enable
      ? getFixtureListKey({
          ...req,
          lang: locale,
          page_size: req?.page_size ?? PAGE_SIZE,
        })
      : _.constant(null),
    ([_, params]) => {
      return getFixtureList(params);
    },
    { ...opts },
  );

  const { data, isLoading, isValidating, setSize } = res;

  const { cache } = useSWRConfig();
  useUnmount(() => {
    if (!disableCache) return;

    const keys = Array.from(cache.keys());

    for (const k of keys) {
      if (k.includes('getFixtureList')) {
        cache.delete(k);
      }
    }
  });

  const hasMore = (_.last(data)?.meta?.total_count ?? 0) >= PAGE_SIZE;

  const loading = isLoading || isValidating;
  const loadMore = () => !loading && hasMore && setSize((prev) => prev + 1);

  return { loading, hasMore, data, loadMore };
};
