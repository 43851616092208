import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useKeenSlider } from 'keen-slider/react';
import { FCC } from 'react';
import { TournamentItem } from 'src/_pages/tournament/tournament-item';
import { getHotTournaments } from 'src/api/tournament';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import TournamentLoading from 'src/components/core/loading/tournament-loading';
import { MyLink } from 'src/components/core/mine/my-link';
import { Paths } from 'src/utils/constants/path';
import { KeenSliderPlugins } from 'src/utils/helpers/etc';
import { getHotTournamentsKey } from 'src/utils/helpers/swr';
import useSWR from 'swr';

const BLUR_IMAGE =
  'data:image/webp;base64,UklGRgYDAABXRUJQVlA4WAoAAAAgAAAAuQAAuQAASUNDUMgBAAAAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADZWUDggGAEAAPAPAJ0BKroAugA+7XaxVimnJCOgSKEwHYlpbt31/r/2r+OaAL5+AFp6aktP3vWPg/5YXSiFoOOm/uPsoUmj3+WVnYr6lOweYWf2RL4dg8tm/8y0pqap3GXzdTvDyn97xzgK1QX1gZAkS9j/P6CIjv4mz/D/+p6em89H/3TtNnmFRIUKn10KAagA/u2vGXM2iDr320iKUi8Ilay2zc37wYbrlLC0YstAZAQ4lM+AaRMWofgSfj5XH7frm3ArGjchUdd/pW0j0hEbRUtDl7198JECaAiPOSnrmQZycw2dOi/ZeuYgIGJz9BAXL5n+44oAG7muVvaF1DvchIdMWBo3pUNJ7J+8yT+dmBfsf08jpVMOA1yw5iZAAAA=';
export const HotTournaments: FCC<{ lazyLoading?: boolean }> = ({
  lazyLoading,
}) => {
  const { data: lives, error } = useSWR(
    lazyLoading ? null : getHotTournamentsKey(),
    getHotTournaments,
  );

  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 1.2,
        spacing: 16,
      },
      breakpoints: {
        '(min-width: 500px)': {
          slides: {
            perView: 2,
            spacing: 16,
          },
        },
        '(min-width: 768px)': {
          slides: {
            perView: 3,
            spacing: 16,
          },
        },
      },
    },
    KeenSliderPlugins,
  );

  const isLoading = lazyLoading ?? (!lives && !error);

  if (!isLoading && !lives?.items?.length) return null;

  return (
    <Box
      sx={{
        mb: 3,
      }}>
      <Box
        sx={{
          alignItems: 'center',
          mb: 2,
        }}>
        <MyLink
          href={Paths.Tournaments}
          color="inherit"
          sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}>
            <Trans id="tournaments.hot_tournaments">
              Crypto betting tournaments
            </Trans>
          </Typography>
          <div style={{ marginLeft: 8, marginBottom: '-4px' }}>
            <ArrowRight />
          </div>
        </MyLink>
      </Box>

      {isLoading && (
        <Box
          sx={{
            mb: 2,
            display: 'grid',
            gridColumnGap: '16px',
            gridTemplateColumns: '1fr 1fr',
          }}>
          {[...Array(2)].map((o, index) => (
            <Box key={index} sx={{ flex: 1 }}>
              <TournamentLoading />
            </Box>
          ))}
        </Box>
      )}
      {!isLoading && (
        <div ref={sliderRef} className="keen-slider">
          {lives?.items?.map((o, index) => (
            <Box className="keen-slider__slide" key={index}>
              <TournamentItem
                sx={{ height: '100%' }}
                data={o}
                useNexImage
                nextImageProps={{
                  alt: o?.name,
                  src: o?.banner,
                  quality: 40,
                  fill: true,
                  placeholder: 'blur',
                  blurDataURL: BLUR_IMAGE,
                  sizes: '33vw',
                  loading: 'lazy',
                }}
              />
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
};
