import { Trans } from '@lingui/macro';
import { Box, Button, DialogContent, Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import WarningIcon from 'src/assets/icons/warning2.svg';
import { BaseDialog } from 'src/components/core/base-dialog';
import { LoadingButton } from 'src/components/core/loading-button';
import { DialogFn, useDialog } from 'src/hooks/use-dialog';
import {
  cancelDialogEmailExpiredAtom,
  emailVerifyExpireAtAtom,
  profileAtom,
} from 'src/store/auth';
export type ActivationExpiredDialogFn = DialogFn;

export const ActivationExpiredDialog = React.forwardRef<
  ActivationExpiredDialogFn,
  any
>((props, ref) => {
  const email = useAtomValue(profileAtom)?.email;
  const { open, handleReject } = useDialog(ref);
  const [sending, setSending] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const setEmailVerifyExpireAt = useSetAtom(emailVerifyExpireAtAtom);
  const setCancel = useSetAtom(cancelDialogEmailExpiredAtom);

  const handleResendVerifyEmail = async () => {
    try {
      setSending(true);
      await setEmailVerifyExpireAt();
      enqueueSnackbar(
        <Trans id="resendEmail.success">
          Verification email sent to {email}
        </Trans>,
        {
          variant: 'success',
        },
      );
      handleReject();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setSending(false);
    }
  };

  const onClose = () => {
    handleReject();
    setCancel(true);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      showCloseIcon
      closeIconProps={{ sx: { top: '4px', right: '4px' } }}
      maxWidth="sm">
      <DialogContent
        sx={{
          padding: 3,
        }}>
        <Stack spacing={4} alignItems="center">
          <Stack spacing={1} alignItems="center">
            <Box
              alignSelf="center"
              color="warning.main"
              sx={{
                '& path': {
                  fill: 'currentcolor',
                },
              }}>
              <WarningIcon width="32" height="32" fill="currentColor" />
            </Box>

            <Typography
              textAlign="center"
              sx={{ fontWeight: 600, fontSize: 18, lineHeight: '23px' }}>
              <Trans id="activation.warning.title">
                Verification email has expired
              </Trans>
            </Typography>
            <Typography textAlign="center">
              <Trans id="activation.warning.sub_title">
                Select resend email below to continue your email verification
              </Trans>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ width: 'calc(50% - 8px)' }}>
              <Trans id="common.cancel">Cancel</Trans>
            </Button>
            <LoadingButton
              type="button"
              size="large"
              variant="contained"
              loading={sending}
              onClick={handleResendVerifyEmail}
              sx={{ width: 'calc(50% - 8px)' }}>
              <Trans id="activation.warning.resend_email">Resend Email</Trans>
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
});

ActivationExpiredDialog.displayName = 'ActivationExpiredDialog';
