import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      backgroundColor: theme.custom.bg[2],
    },
  };
});

export default useStyles;
