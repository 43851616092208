import { Box, Skeleton } from '@mui/material';

const TournamentLoading = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        '&> span': {
          transform: 'scale(1, 1)',
        },
      }}>
      <Skeleton height={350} />
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Skeleton
          variant="rectangular"
          height={70}
          width={'80%'}
          sx={{
            '&> span': {
              transform: 'scale(1, 1)',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TournamentLoading;
