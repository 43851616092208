'use client';

import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import ScrollableTabs from 'src/components/core/scrollable-tabs';
import { useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import { Paths } from 'src/utils/constants/path';
import BackToWallet from '../back-to-wallet';
import { useEffect } from 'react';

const DepositTab = dynamic(
  () => import('./deposit-tab').then((x) => x.DepositTab),
  { ssr: false },
);
const PurchaseTab = dynamic(
  () => import('./purchase-tab').then((x) => x.PurchaseTabPage),
  { ssr: false },
);
const FiatDepositTab = dynamic(
  () => import('./fiat-tab').then((x) => x.FiatDepositTab),
  { ssr: false },
);

export enum TabKeys {
  FIAT = 'fiat',
  DEPOSIT = 'deposit',
  PURCHASE = 'purchase',
}
const DEPOSIT_TABS = [
  {
    title: <Trans id="deposit.fiat">Fiat</Trans>,
    value: TabKeys.FIAT,
  },
  {
    title: <Trans id="deposit.crypto">Crypto</Trans>,
    value: TabKeys.DEPOSIT,
  },
  {
    title: <Trans id="funkit_deposit.crypto">Buy crypto</Trans>,
    value: TabKeys.PURCHASE,
  },
];

const ContentPanel = ({ tab, symbol }: { tab: string; symbol: string }) => {
  switch (tab) {
    case TabKeys.FIAT:
      return <FiatDepositTab initialCoin={symbol} />;

    case TabKeys.DEPOSIT:
      return <DepositTab initialCoin={symbol} />;

    case TabKeys.PURCHASE:
      return <PurchaseTab initialCoin={symbol} />;
  }
};

const COUNTRY_TO_SYMBOL: { [key: string]: string } = {
  ca: 'CAD',
  br: 'BRL',
};

const FIAT_DEFAULT_COIN = 'CAD';
const CRYPTO_DEFAULT_COIN = 'ETH';

export const DepositPage: NextPage<{ userCountry: string }> = ({
  userCountry,
}) => {
  const router = useMyRouter();
  const defaultTab =
    userCountry && COUNTRY_TO_SYMBOL[userCountry]
      ? TabKeys.FIAT
      : TabKeys.DEPOSIT;

  const { coin_symbol: _symbol = undefined, tab = defaultTab } = useQuery([
    'coin_symbol',
    'tab',
  ]);

  const activeTab = tab || DEPOSIT_TABS[1].value;

  useEffect(() => {
    if (!_symbol && userCountry && COUNTRY_TO_SYMBOL[userCountry]) {
      router.push(Paths.Deposit, {
        query: {
          coin_symbol: COUNTRY_TO_SYMBOL[userCountry],
          tab: TabKeys.FIAT,
        },
      });
    } else if (!_symbol && !COUNTRY_TO_SYMBOL[userCountry]) {
      // Navigate to Crypto
      router.push(Paths.Deposit, {
        query: {
          coin_symbol: CRYPTO_DEFAULT_COIN,
          tab: TabKeys.DEPOSIT,
        },
      });
    }
  }, [_symbol, userCountry]);

  return (
    <>
      <Box width="100%">
        <Typography variant="18">
          <Trans id="deposit">Deposit</Trans>
        </Typography>
        <Box sx={{ mt: 1.5 }}>
          <ScrollableTabs
            tabs={DEPOSIT_TABS}
            allowScrollButtonsMobile={false}
            scrollButtons={false}
            value={activeTab}
            onChange={(_, value) => {
              router.push(Paths.Deposit, {
                query: {
                  ...(value === TabKeys.FIAT
                    ? {
                        coin_symbol:
                          (userCountry && COUNTRY_TO_SYMBOL[userCountry]) ||
                          FIAT_DEFAULT_COIN,
                      }
                    : { coin_symbol: CRYPTO_DEFAULT_COIN }),
                  tab: value,
                },
              });
            }}
            tabProps={{
              sx: {
                minWidth: `${100 / DEPOSIT_TABS.length}%`,
                fontSize: '14px !important',
              },
            }}
          />
        </Box>
        {_symbol && <ContentPanel tab={activeTab} symbol={_symbol} />}
        <BackToWallet />
      </Box>
    </>
  );
};
