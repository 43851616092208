import { useCallback, useImperativeHandle, useRef, useState } from 'react';

export type DialogFn<T = void, Args = unknown> =
  | {
      open: (_args?: Args) => Promise<T | undefined>;
      close: () => void;
    }
  | undefined;

export function useDialog<T, Args = unknown>(
  ref: React.ForwardedRef<DialogFn<T, Args>>,
) {
  const [openState, setOpenState] = useState(false);
  const [params, setParams] = useState<Args>();

  const promiseRef = useRef<
    { resolve: (_value: T | undefined) => void } | undefined
  >();

  const open = useCallback((args?: Args) => {
    setParams(args);
    setOpenState(true);
    return new Promise<T | undefined>((resolve) => {
      promiseRef.current = { resolve };
    });
  }, []);

  const close = useCallback(() => {
    setOpenState(false);
    promiseRef.current = undefined;
  }, []);
  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  const handleResolve = useCallback(
    (next: T, _close: boolean = true) => {
      promiseRef.current?.resolve(next);
      if (_close) {
        close();
      }
    },
    [close],
  );

  const handleReject = useCallback(() => {
    setParams(undefined);
    promiseRef.current?.resolve(undefined);
    close();
  }, [close]);

  return { params, open: openState, handleReject, handleResolve };
}
