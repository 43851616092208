import { useAtom } from 'jotai';
import _ from 'lodash';
import { useEffect } from 'react';
import type { SocketEvent } from 'src/utils/constants/socket-event';
import { useStableFn } from '../use-stable-fn';
import { socketAtom } from './use-socket';

export function useSocketEventFn(
  forEvent: SocketEvent | SocketEvent[],
  callback: (...arg: any[]) => any,
  enabled = true,
) {
  const [socket, registerListener] = useAtom(socketAtom);

  const events = _.isArray(forEvent) ? forEvent : [forEvent];

  const handler = useStableFn(callback);

  const _enabled = enabled && Boolean(socket);

  useEffect(() => {
    if (!_enabled) return;

    const cleanupFns = events.map((e) => {
      return registerListener(e, handler);
    });

    return () => {
      cleanupFns.forEach((fn) => {
        fn?.();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...events, registerListener, _enabled]);
}
